/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  SelectableRows,
} from "mui-datatables";
import React, { ChangeEvent, Component, Fragment, ReactNode } from "react";
import {
  BackendFactory,
  Device,
  Event,
  EventObserver,
  EventSet,
  EventState,
  EventsRepository,
  Maybe,
  TimePeriod,
  timePeriodIsValid,
} from "@sade/data-access";
import Loader from "../ui/loader";
import { DeviceAndTime, DeviceChangeType, idFromProps, parseDeviceAndTime } from "../../utils/NavigationUtils";
import TimeRangePicker from "../ui/time-range-picker";
import LiveControl from "../ui/live-control";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import { DeviceChangeResultHandler } from "../../utils/DeviceChangeResultHandler";
import DeviceNavigationCache from "../../utils/DeviceNavigationCache";
import DeviceDrawer from "../drawers/device-drawer";
import { translations } from "../../generated/translationHelper";
import { ContentSplash } from "../ui/content-splash";
import { convertStringToTimestamp, convertTimestampToString, DateTimeFormatTarget } from "../../utils/TimeUtils";
import MeasurementCharts from "./components/measurement-charts";
import DownloadDialog from "../ui/download-dialog";
import accessControlled from "../access-control/access-controlled";
import ViewAccessMethods from "../../ViewAccessMethods";

type Props = RouteComponentProps<DevicePathRouterProps>;

interface State {
  liveMode: boolean;
  searchParam: string;
  device?: Device;
  eventSet?: EventSet;
  events?: Event[];
  tableData?: EventTableRow[];
  showDownloadPopup: boolean;
  downloadUrl?: string;
  downloadFingerprint?: string;
  gettingLink?: string;
  isOwner: boolean;
}

const DEFAULT_TIME_WINDOW_MS: number = 14 * 24 * 60 * 60 * 1000;
const RESET_STATE = { eventSet: undefined, liveMode: false, device: undefined };

type EventTableCell = string | number | JSX.Element;
type EventTableRow = EventTableCell[];

const OwnerContent = accessControlled(React.Fragment, ["fingerprintUrlGet"]);

class MeasurementView extends Component<Props, State> implements EventObserver {
  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "timestamp",
      label: "timestamp",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "measurement",
      label: "Measurement",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cellId",
      label: "ID (QR-code)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cellTypeName",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "grading",
      label: "grading",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "measurementSetId",
      options: {
        display: false,
      },
    },
    {
      name: "group",
      options: {
        display: false,
      },
    },
  ];

  private theme = createTheme({
    components: {
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            backgroundColor: "#f5f5f5",
          },
        },
      },
    },
  });

  private renderInnerTableModule(event: Event): EventTableCell {
    const metadata = event.metadata ? JSON.parse(event.metadata.replace(/\bNaN\b/g, "null")) : undefined;

    return (
      <TableRow key={event.measurementId}>
        <TableCell align="left">
          {convertTimestampToString(parseInt(metadata.measurementStartTimeDevice), DateTimeFormatTarget.EventsTable)}
        </TableCell>
        <TableCell align="left">
          {convertTimestampToString(parseInt(metadata.measurementEndTimeDevice), DateTimeFormatTarget.EventsTable)}
        </TableCell>
        <TableCell align="left">{metadata.module_result?.cell_result_max?.cell_number}</TableCell>
        <TableCell align="left">{metadata.module_result?.cell_result_min?.cell_number}</TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
      </TableRow>
    );
  }

  private renderInnerTableModules(measurementSet: Event[]): ReactNode | ReactNode[] {
    if (measurementSet) {
      return measurementSet.map((event: Event) => {
        return this.renderInnerTableModule(event);
      });
    }
  }

  private renderInnerModuleTable(measurementSet: Event[]): ReactNode | ReactNode[] {
    return (
      <Fragment>
        <TableHead>
          <TableRow>
            <TableCell>Start time</TableCell>
            <TableCell>End time</TableCell>
            <TableCell>Max result #</TableCell>
            <TableCell>Min result #</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this.renderInnerTableModules(measurementSet)}</TableBody>
      </Fragment>
    );
  }

  private getFingerprintFilename(fingerprint: string): string {
    if (!fingerprint) return "";
    const split = fingerprint.split("/");
    return split[split.length - 1];
  }

  private async getFingerprintDownloadUrl(fingerprint: string): Promise<Maybe<string>> {
    const fingerprintUrl = await BackendFactory.getBackend().getFingerprintDownloadUrl(fingerprint);
    console.log("Fingerprint URL: ", fingerprintUrl);
    return fingerprintUrl;
  }

  private async downloadFingerprint(fingerprint: string): Promise<void> {
    this.setState({ gettingLink: fingerprint });
    const filename = await this.getFingerprintDownloadUrl(fingerprint);
    this.setState({
      downloadUrl: filename,
      showDownloadPopup: true,
      downloadFingerprint: fingerprint,
      gettingLink: undefined,
    });
  }

  private renderInnerTableCell(event: Event): EventTableCell {
    const metadata = event.metadata ? JSON.parse(event.metadata.replace(/\bNaN\b/g, "null")) : undefined;
    return (
      <TableRow key={event.measurementId}>
        <TableCell align="left">{metadata.cellNumber ? metadata.cellNumber : "1"}</TableCell>
        <TableCell align="left">{metadata.cellId && metadata.cellId}</TableCell>
        <TableCell align="left">{metadata.cellTypeName && metadata.cellTypeName}</TableCell>
        <TableCell align="left">{metadata.cellTemperature && metadata.cellTemperature.toFixed(3)}</TableCell>
        <TableCell align="left">{metadata.cellVoltage && parseFloat(metadata.cellVoltage).toFixed(3)}</TableCell>
        <TableCell align="left">{metadata.grading && metadata.grading}</TableCell>
        <TableCell align="left">
          <Grid container={true} direction="row">
            <Grid item={true}>{metadata && this.getFingerprintFilename(metadata.fingerprint)}</Grid>
            {this.state.isOwner && (
              <Grid
                item={true}
                onClick={(): void => {
                  this.downloadFingerprint(metadata.fingerprint);
                }}
              >
                <OwnerContent showAccessError={false}>
                  {this.state.gettingLink === metadata.fingerprint ? (
                    <Loader size={"small"} topBottomPadding={"0"} />
                  ) : (
                    <IconButton
                      style={{ padding: 0, paddingLeft: "0.2rem" }}
                      id="download"
                      onClick={(): void => {
                        this.downloadFingerprint(metadata.fingerprint);
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  )}
                </OwnerContent>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }

  private renderInnerTableCells(measurementSet: Event[]): ReactNode | ReactNode[] {
    if (measurementSet) {
      return measurementSet
        .sort((a, b) => (a.cellNumber ?? 0) - (b.cellNumber ?? 0))
        .map((event: Event) => {
          return this.renderInnerTableCell(event);
        });
    }
  }

  private renderInnerCellTable(measurementSet: Event[]): ReactNode | ReactNode[] {
    return (
      <Fragment>
        <TableHead>
          <TableRow style={{ width: "100%", borderWidth: 1 }}>
            <TableCell>#</TableCell>
            <TableCell>Cell ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Temp c</TableCell>
            <TableCell>Voltage</TableCell>
            <TableCell>Grading</TableCell>
            <TableCell>Fingerprint</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this.renderInnerTableCells(measurementSet)}</TableBody>
      </Fragment>
    );
  }

  private renderErrorTableRow(): ReactNode {
    return (
      <TableRow>
        <TableCell colSpan={7} style={{ textAlign: "center" }}>
          {translations.events.texts.invalidMeasurementData()}
        </TableCell>
      </TableRow>
    );
  }

  private renderInnerTable(measurementSetId: string, colSpan: number): ReactNode {
    const moduleEvents = this.state.events?.filter(
      (event) =>
        event.measurementSetId === measurementSetId && event.eventId === "event-module-measured" && event.measurementId
    );
    const cellEvents = this.state.events?.filter(
      (event) =>
        event.measurementSetId === measurementSetId && event.eventId === "event-cell-measured" && event.measurementId
    );

    return (
      <TableRow>
        <TableCell colSpan={colSpan} style={{ width: "80%", padding: "0 0 0 8rem" }}>
          <Table className="measurements-inner-table">
            {moduleEvents && moduleEvents.length > 0 && this.renderInnerModuleTable(moduleEvents)}
            {cellEvents && cellEvents.length > 0 && this.renderInnerCellTable(cellEvents)}
            {cellEvents?.length === 0 && this.renderErrorTableRow()}
          </Table>
        </TableCell>
      </TableRow>
    );
  }

  private csvExportHeader(): string {
    return "Timestamp, Measurement,ID (QR-code), Type, Grading, MeasurementSetId, Start Time, End Time, Max result #, Min result #, R_Im0_mean, Cell ID, Temp c, Voltage, R 1KHZ, Fingerprint, Metadata";
  }

  private csvExportRowData(event: Event): string {
    const metadata = event.metadata ? JSON.parse(event.metadata.replace(/\bNaN\b/g, "null")) : undefined;
    const metadataString = metadata && this.state.isOwner ? JSON.stringify(metadata).replaceAll('"', '""') : "";
    return (
      convertTimestampToString(parseInt(event.timestamp), DateTimeFormatTarget.EventsTable) +
      "," + // Timestamp
      event.eventId +
      "," + // Measurement
      ((metadata?.cellId ? metadata?.cellId : metadata?.module_result?.module_id) ?? "") +
      "," + // ID (QR-code)
      (metadata?.cellTypeName ?? "Module") +
      "," + // Type
      (metadata?.grading ?? "") +
      "," + // Grading
      (event?.measurementSetId ?? "") +
      "," + // MeasurementSetId
      (metadata?.measurementStartTimeDevice !== "0"
        ? convertTimestampToString(parseInt(metadata.measurementStartTimeDevice), DateTimeFormatTarget.EventsTable)
        : "") +
      "," + // Start Time
      (metadata?.measurementEndTimeDevice !== "0"
        ? convertTimestampToString(parseInt(metadata.measurementEndTimeDevice), DateTimeFormatTarget.EventsTable)
        : "") +
      "," + // End Time
      (metadata?.module_result?.cell_result_max?.cell_number ?? "") +
      "," + // Max result #
      (metadata?.module_result?.cell_result_min?.cell_number ?? "") +
      "," + // Min result #
      (metadata?.module_result?.R_Im0_mean ?? "") +
      "," + // R_Im0_mean
      (metadata?.cellNumber ?? "") +
      "," + // Cell ID
      (metadata?.cellTemperature ?? "") +
      "," + // Temp c
      (metadata?.cellVoltage ?? "") +
      "," + // Voltage
      (metadata?.characteristics?.R_1kHz ?? "") +
      "," + // R 1KHZ
      (metadata?.fingerprint ?? "") +
      "," + // Fingerprint
      ('"' + metadataString + '"')
    ); // Metadata
  }

  private readonly tableOptions: MUIDataTableOptions = {
    sortOrder: {
      name: "timestamp",
      direction: "desc",
    },
    filterType: "checkbox",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    search: false,
    isRowExpandable: (_dataIndex: number, _expandedRows: unknown): boolean => {
      return true;
    },
    renderExpandableRow: (rowData: string[], _rowMeta: unknown): ReactNode => {
      const colSpan = rowData.length + 1;
      return this.renderInnerTable(rowData[5], colSpan);
    },
    // filter: false,
    customToolbarSelect: (
      _selectedRows: unknown,
      _displayData: Array<{ data: unknown[]; dataIndex: number }>,
      _setSelectedRows: unknown
    ): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: unknown): void => {
      // TODO: This is not supported yet.
    },
    onDownload: (_buildHead, _buildBody, _columns, _rows): string | boolean => {
      const events = this.state.events?.filter(
        (event) =>
          event.eventState === EventState.Active &&
          (event.eventId === "event-cell-measured" || event.eventId === "event-module-measured")
      );
      const headerRow = this.csvExportHeader();
      const allRows: string[] = [headerRow];
      const measurementRows =
        events?.map((event) => {
          return this.csvExportRowData(event);
        }) ?? [];
      allRows.push(...measurementRows);
      return allRows?.join("\n") ?? false;
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: true,
    // TODO: Fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customSort: (data: any[], colIndex: number, order: string): any => {
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.sort((a: any, b: any) => {
        // TODO: Fix parameter assign
        // eslint-disable-next-line no-param-reassign
        a = a.data[colIndex] || "";
        // eslint-disable-next-line no-param-reassign
        b = b.data[colIndex] || "";

        const colObject = this.tableColumns[colIndex];
        let col: MUIDataTableColumn;

        if (typeof colObject === "object") {
          col = colObject;
        } else {
          return 0;
        }

        if (order === "asc") {
          if (col.name === "timestamp") {
            return MeasurementView.compareTimestamps(a as string, b as string);
          } else {
            return a.toString().localeCompare(b, undefined, { numeric: true });
          }
        } else if (order === "desc") {
          if (col.name === "timestamp") {
            return MeasurementView.compareTimestamps(b as string, a as string);
          } else {
            return b.toString().localeCompare(a, undefined, { numeric: true });
          }
        }
        return 0;
      });
    },
    textLabels: {
      body: {
        noMatch: translations.events.texts.noEventsFound(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
      toolbar: {
        downloadCsv: translations.common.buttons.downloadCsv(),
        filterTable: translations.common.buttons.filterTable(),
        print: translations.common.buttons.print(),
        search: translations.common.buttons.search(),
        viewColumns: translations.common.buttons.viewColumns(),
      },
      filter: {
        reset: translations.common.buttons.reset(),
        title: translations.common.texts.filters().toUpperCase(),
      },
      viewColumns: {
        title: translations.common.texts.showColumns(),
      },
    },
  };

  private readonly deviceChangeHandler = new DeviceChangeResultHandler({
    stateResetCallback: (): void => this.setState(RESET_STATE),
    deviceChangeCallback: (id: string): Promise<void> => this.changeDevice(id),
  });

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchParam: "",
      liveMode: false,
      showDownloadPopup: false,
      gettingLink: undefined,
      isOwner: false,
    };
  }

  private static compareTimestamps(timeStringA: string, timeStringB: string): number {
    const timestampA = convertStringToTimestamp(timeStringA, DateTimeFormatTarget.StatusTable);
    const timestampB = convertStringToTimestamp(timeStringB, DateTimeFormatTarget.StatusTable);
    return timestampA - timestampB;
  }

  private static getDefaultTimePeriod(): TimePeriod {
    const endTimestamp = Date.now();
    return {
      endTimestamp,
      startTimestamp: endTimestamp - DEFAULT_TIME_WINDOW_MS,
    };
  }

  public async componentDidMount(): Promise<void> {
    const deviceResolveResult = DeviceNavigationCache.getInstance().predictDeviceChange(this.props);
    if (deviceResolveResult === DeviceChangeType.ChangedToNew) {
      const { deviceId, timePeriod } = parseDeviceAndTime(this.props);
      if (deviceId) await this.changeDevice(deviceId, timePeriod);
    } else if (deviceResolveResult === DeviceChangeType.WillRestore) {
      const deviceId = DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
      if (deviceId) await this.setUrlFromRouteInfo({ deviceId, timePeriod: {} });
    }
    this.setState({ isOwner: await ViewAccessMethods.hasOwnerAccess() });
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    await DeviceNavigationCache.getInstance().resolveDeviceChange(this.deviceChangeHandler, this.props, prevProps);
  }

  public componentWillUnmount(): void {
    this.state.eventSet?.removeObserver(this);
  }

  public async onEventSetUpdate(eventSet: EventSet): Promise<void> {
    if (this.state.liveMode && this.state.device?.getId() === eventSet.getId()) {
      const events = eventSet.getData();
      if (this.state.searchParam === "") {
        const tableData = this.getTableData(events);
        this.setState({
          eventSet,
          events,
          tableData,
        });
      } else {
        const eventSetIds = this.getMeasurementSetIdsContainingSearchParam(events, this.state.searchParam);
        const filteredEvents = events.filter((event) => eventSetIds.includes(event.measurementSetId!));
        const tableData = this.getTableData(filteredEvents);
        this.setState({
          eventSet,
          events: filteredEvents,
          tableData,
        });
      }
      await this.setUrlFromRouteInfo({ deviceId: this.state.device.getId(), timePeriod: eventSet.getTimePeriod() });
    }
  }

  private async changeDevice(newDeviceId: string, timePeriod?: Partial<TimePeriod>): Promise<void> {
    this.state.eventSet?.removeObserver(this);

    const setNewDevice = async (): Promise<void> => {
      const newTimePeriod =
        timePeriod && timePeriodIsValid(timePeriod) ? timePeriod : MeasurementView.getDefaultTimePeriod();
      const device = await DeviceNavigationCache.getInstance().setCurrentDevice(newDeviceId);
      this.setState({ device }, async () => {
        await this.updateUrlTimestampAndFetchEvents(newTimePeriod);
      });
    };

    this.setState({ ...RESET_STATE }, setNewDevice);
  }

  private async setUrlFromRouteInfo(routeInfo?: DeviceAndTime): Promise<void> {
    await DeviceNavigationCache.getInstance().navigateToDeviceAndTime(this.props, routeInfo);
  }

  private getMeasurementSetIdsContainingSearchParam(events: Event[], searchParam: string): string[] {
    const filteredEvents = events.filter((event) => event.metadata?.includes(searchParam));
    const measurementSetIdsSet = new Set(filteredEvents.map((event) => event.measurementSetId!));
    return Array.from(measurementSetIdsSet);
  }

  private async fetchDeviceEvents(timePeriod: TimePeriod): Promise<void> {
    if (!this.state.device || !timePeriodIsValid(timePeriod)) {
      return;
    }
    try {
      const eventSet = await this.state.device.getEventSet(timePeriod.startTimestamp, timePeriod.endTimestamp);

      if (eventSet) {
        if (this.state.liveMode) eventSet?.addObserver(this);
        const events = eventSet.getData();
        if (this.state.searchParam === "") {
          const tableData = this.getTableData(events);
          this.setState({
            eventSet,
            events,
            tableData,
          });
        } else {
          const eventSetIds = this.getMeasurementSetIdsContainingSearchParam(events, this.state.searchParam);
          const filteredEvents = events.filter((event) => eventSetIds.includes(event.measurementSetId!));
          const tableData = this.getTableData(filteredEvents);
          this.setState({
            eventSet,
            events: filteredEvents,
            tableData,
          });
        }
      }
    } catch (error) {
      console.error("fetchDeviceEvents", error);
    }
  }

  private groupEventsByMeasurementSetId(events: Event[]): Event[][] {
    const groupedEvents: Event[][] = [];
    let currentGroup: Event[] = [];
    let currentMeasurementSetId: Maybe<string> = undefined;

    events.forEach((event) => {
      if (event.measurementSetId !== currentMeasurementSetId) {
        if (currentGroup.length > 0) {
          groupedEvents.push(currentGroup);
        }
        currentGroup = [];
        currentMeasurementSetId = event.measurementSetId ?? undefined;
      }
      currentGroup.push(event);
    });

    if (currentGroup.length > 0) {
      groupedEvents.push(currentGroup);
    }

    return groupedEvents;
  }

  private getFirstEvent(events: Event[]): Event {
    return events.find((event) => event.eventId === "event-module-measured") ?? events[0];
  }

  private getTableData(data: Event[]): EventTableRow[] {
    const events = data.filter(
      (event) =>
        event.eventState === EventState.Active &&
        (event.eventId === "event-cell-measured" || event.eventId === "event-module-measured")
    );
    const groupedEvents = this.groupEventsByMeasurementSetId(events);

    return (groupedEvents ?? [])
      .map((group) => {
        try {
          const firstEvent = this.getFirstEvent(group);
          if (!firstEvent.metadata) {
            return [];
          }
          const maxGradingCell = group
            .filter((event) => event.eventId === "event-cell-measured")
            .reduce((prev, curr) => {
              const prevMetadata = prev.metadata ? JSON.parse(prev.metadata) : undefined;
              const currMetadata = curr.metadata ? JSON.parse(curr.metadata) : undefined;

              return prevMetadata?.grading > currMetadata?.grading ? prev : curr;
            }, group[0]);
          const maxGrading = maxGradingCell.metadata ? JSON.parse(maxGradingCell.metadata).grading : undefined;
          const metadata = JSON.parse(firstEvent.metadata.replace(/\bNaN\b/g, "null"));
          const data: EventTableRow = [
            convertTimestampToString(parseInt(metadata.measurementStartTimeDevice), DateTimeFormatTarget.EventsTable),
            firstEvent.eventId ? EventsRepository.instance.getEventDescription(firstEvent.eventId) : "",
            metadata.cellId ?? metadata.module_result.module_id,
            metadata.cellTypeName ?? "Module",
            maxGrading,
            firstEvent.measurementSetId,
            group,
          ];
          return data;
        } catch (error) {
          console.error("invalid data format!");
          return [];
        }
      })
      .filter((data) => data.length !== 0);
  }

  private handleTimeRangeSelect = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
    if (this.state.device) {
      await this.updateUrlTimestampAndFetchEvents({ startTimestamp, endTimestamp });
    }
  };

  private handleLiveDataToggle = (): void => {
    const enablingLiveMode = !this.state.liveMode && !!this.state.device;

    if (enablingLiveMode) {
      this.setState({ liveMode: true }, async () => {
        const { timePeriod } = parseDeviceAndTime(this.props);
        const { startTimestamp } = MeasurementView.getDefaultTimePeriod();
        const newTimePeriod = {
          startTimestamp: timePeriod.startTimestamp ?? startTimestamp,
          endTimestamp: Date.now(),
        };

        await this.updateUrlTimestampAndFetchEvents(newTimePeriod);
      });
    } else {
      this.setState({ liveMode: false });
      this.state.eventSet?.removeObserver(this);
    }
  };

  private handleDeviceSelect = async (device?: Device): Promise<void> => {
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, device);
  };

  private async updateUrlTimestampAndFetchEvents(timePeriod: TimePeriod): Promise<void> {
    await Promise.all([
      this.setUrlFromRouteInfo({ deviceId: this.state.device?.getId(), timePeriod }),
      this.fetchDeviceEvents(timePeriod),
    ]);
  }
  private handleSearchParamChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchParam: event.target.value });
  };

  private renderToolbar(): ReactNode {
    if (!this.state.device) return;
    const { startTimestamp, endTimestamp } = parseDeviceAndTime(this.props).timePeriod;
    return (
      <div className="iot-tool-container col-sm-9 col-xsm-12">
        <Grid container={true} spacing={2} alignItems="center" style={{ height: "100%", margin: 0 }}>
          <Grid item={true}>
            <TextField
              id="query-label"
              label={"Search"}
              variant="standard"
              value={this.state.searchParam}
              onChange={this.handleSearchParamChange}
            />
          </Grid>
          <Grid item={true}>
            <TimeRangePicker
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              onTimeRangeSelect={this.handleTimeRangeSelect}
              disabled={this.state.liveMode}
            />
          </Grid>
          <Grid item={true}>
            <LiveControl liveData={this.state.liveMode} onLiveDataToggle={this.handleLiveDataToggle} />
          </Grid>
        </Grid>
      </div>
    );
  }

  private renderMeasurementsTable(): ReactNode {
    const data = this.state.tableData; //this.getTableData().filter((data) => data.length !== 0);
    if (!this.state.events) return;
    if (!data) return;
    return (
      <ThemeProvider theme={this.theme}>
        <MUIDataTable
          title={translations.common.texts.measurements()}
          data={data}
          columns={this.tableColumns}
          options={this.tableOptions}
        />
      </ThemeProvider>
    );
  }

  private renderDeviceDrawer(): ReactNode {
    return <DeviceDrawer onDeviceSelect={this.handleDeviceSelect} selectedDeviceId={idFromProps(this.props)} />;
  }

  private renderInstructions(): ReactNode {
    if (this.state.device) return;

    return <ContentSplash>{translations.events.texts.selectDevice()}</ContentSplash>;
  }

  private renderContent(): ReactNode {
    const { events } = this.state;
    if (!events) return <Loader />;
    return (
      <div className="iot-content-container col-sm-12 col-xsm-12">
        {this.renderInstructions()}
        <MeasurementCharts events={events} />
        {this.renderMeasurementsTable()}
      </div>
    );
  }

  private renderDownloadPopup(): ReactNode {
    if (this.state.showDownloadPopup && this.state.downloadUrl && this.state.downloadFingerprint) {
      return (
        <DownloadDialog
          url={this.state.downloadUrl}
          filename={this.getFingerprintFilename(this.state.downloadFingerprint)}
          onClose={(): void => this.setState({ showDownloadPopup: false })}
        />
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderDeviceDrawer()}
        {this.renderToolbar()}
        {this.renderContent()}
        {this.renderDownloadPopup()}
      </Fragment>
    );
  }
}

export default withRouter(MeasurementView);
